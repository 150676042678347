.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* Full height of the viewport */
  /* height: 100vh; */
  /* background-color: rgb(225, 225, 225); */
  /* background-color: whitesmoke; */
}

.App-header {
  /* background-color: #f1f1f2; */
  background-color: white;
  /* background-image: url("assets/images/bg-usa-short.png"); */
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid #838383;
}

.App-link {
  color: #61dafb;

}

/* .App-body {
  padding-top: 48px;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.MuiGrid-spacing-xs-1::-webkit-scrollbar {
  width: 100% !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.MuiGrid-spacing-xs-1 {
  width: 100% !important;
}

body {
  background-color: #e3e6e6;
  width: 100% !important;
}

body.modal-open {
  overflow: scroll;
  width: 100%;
}

html {
  overflow-y: scroll;
}

.banner-container {
  height: 200px;
  position: relative;
}

.banner-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}